<template>
    <div id="zoom-client-element" />
</template>

<script>
// https://socket.io/docs/v4/client-api/
import { io } from 'socket.io-client'

import axios from "axios"

import { ZoomSignatureController } from '../controllers/ZoomSignatureController'
import { ZoomMtgController } from '../controllers/ZoomMtgController'
import { WatermarkController } from '../controllers/WatermarkController'

import { JoinedMeetingEvent } from '../events/JoinedMeetingEvent'

export default {
    name: 'LaiZoomClient',

    props: {
        token: {
            type: String,
            default: ''
        },

        userId: {
            type: Number,
            default: 0
        },

        courseId: {
            type: Number,
            default: 0
        },

        liveClassId: {
            type: Number,
            default: 0
        },

        leaveUrl: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            appApiUrl: process.env.VUE_APP_API_URL,
            sockerUrl: process.env.VUE_APP_SOCKET_URL,
            zoomVersion: process.env.VUE_APP_ZOOM_VERSION,

            socket: {},

            userRoles: ['STUDENT'],
            tc: '',
            ipAddress: '',

            joinAudioInterval: null,

            isMeetingInitialized: false,
        }
    },

    computed: {
        cIsUserHost () {
            return this.cIsUserAdmin || this.cIsUserTeacher
        },

        cIsUserAdmin () {
            return this.userRoles.includes('ADMIN')
        },

        cIsUserTeacher () {
            return this.userRoles.includes('TEACHER')
        },

        cIsAllowedToBoot () {
            return this.token && this.courseId > 0 && this.liveClassId > 0 && this.userId && this.leaveUrl
        },

        cSignatureApi () {
            return `${this.appApiUrl}/v2/courses/${this.courseId}/live-classes/${this.liveClassId}/${this.cIsUserHost ? 'teacher-signature' : 'signature'}/`
        }
    },

    watch: {
        cIsAllowedToBoot (newValue) {
            if (newValue && !this.isMeetingInitialized) {
                this.bootZoomClient()
            }
        }
    },

    methods: {
        async loadDependencies () {
            // socket serverina baglanalim
            this.socket = io(this.sockerUrl, {
                // auht, query ...
            })

            window.ZoomMtg.setZoomJSLib(`https://source.zoom.us/${this.zoomVersion}/lib`, '/av')
            await window.ZoomMtg.preLoadWasm()
            await window.ZoomMtg.prepareWebSDK()

            // kendi türkçe dil objemiz "/lang/tr-TR.json" bu dosya üzerinde düzenleme yapılabilir.
            // Must call ZoomMtg.i18n.load before ZoomMtg.init to make internationalization and localization (i18n) work.
            await window.ZoomMtg.i18n.load("/lang/tr-TR.json", "tr-TR")

            if (this.cIsAllowedToBoot) {
                this.bootZoomClient()

                document.addEventListener(JoinedMeetingEvent.name, () => {
                    this.isMeetingInitialized = true
                    
                    if (this.cIsUserTeacher) {
                        this.informStudents()
                    }
                    this.configureCustomUI()
                })
            }
        },

        bootZoomClient () {
            this.fetchUserData()
                .then(async () => {
                    const signatureController = new ZoomSignatureController(this.cSignatureApi, this.token)
                    await signatureController.getSignature()
                    
                    const mtgController = new ZoomMtgController(this.leaveUrl)
                    if (this.cIsUserHost) {
                        mtgController.authorizeForHost()
                    } else {
                        mtgController.authorizeForAttendee()
                    }
                    mtgController.initMeeting(signatureController.getMeetingConfigs())
                })
        },

        informStudents () {
            // ögrencileri de egitime katabilmek icin app icerisinde buton gösterelim
            this.socket.emit('initiate-course', this.courseId)
        },
        
        async fetchUserData () {
            return new Promise((resolve, reject) => {
                Promise.all([
                    axios.get(`${this.appApiUrl}/v1/get-ip-address`),
                    axios.get(`${this.appApiUrl}/v1/users/${this.userId}`, {
                        headers: {
                            'Authorization': `Token ${this.token}`
                        }
                    })
                ])
                    .then(([ipResponse, userResponse]) => {
                        this.ipAddress = ipResponse.data.ip
                        this.tc = userResponse.data.tc_kimlik_number
                        this.userRoles = userResponse.data.auth_role
    
                        resolve(true)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        configureCustomUI () {
            if (this.cIsUserHost) {
                const htmlElement = document.querySelector('html')
                const htmlElementClasses = htmlElement.classList
                
                htmlElementClasses.add('meeting-host')
                
                if (this.cIsUserTeacher) {
                    htmlElementClasses.add('meeting-teacher')
                }

                this.setChatConfig()
            } else {
                this.setWatermarks()
                
                setTimeout(() => {
                    this.activateAudio()
                }, 5000)
            }
        },

        activateAudio () {
            this.joinAudioInterval = setInterval(() => {
                const joinAudioBtn = document.querySelector('.join-audio-container__btn[aria-label="join audio"]')
                if (joinAudioBtn) {
                    // 'unmute my microphone' 'mute my microphone' 'join audio'
                    joinAudioBtn.click()
                    clearInterval(this.joinAudioInterval)
                    this.joinAudioInterval = null
                }
            }, 1000)
        },

        setChatConfig () {
            let isChatOpened = false

            const setChatConfigInterval = setInterval(() => {
                const chatButton = document.querySelector("[aria-label*='chat']")

                const onChatOpenedForChatButton = () => {
                    const onChatOpenedForChatButtonInterval = setInterval(() => {
                        if (document.querySelector(".chat-container.window-content-bottom")) {
                            isChatOpened = true
                            chatButton.removeEventListener("click", onChatOpenedForChatButton)
                            clearInterval(onChatOpenedForChatButtonInterval)
                        }
                    }, 1000)
                }

                if (!isChatOpened && chatButton) {
                    chatButton.addEventListener("click", onChatOpenedForChatButton)
                    chatButton.click()
                }

                if (document.querySelector(".chat-container__chat-control ul li:nth-child(3) a")) {
                    document.querySelector(".chat-container__chat-control ul li:nth-child(3) a").click()
                    clearInterval(setChatConfigInterval)
                }
            }, 1000)
        },

        setWatermarks () {
            const watermarkController = new WatermarkController()
            watermarkController.setWatermarks([
                {
                    text: this.tc,
                },
                {
                    text: this.ipAddress,
                    style: {
                        top: '50px',
                    }
                }
            ])
        },
    },

    mounted () {
        this.loadDependencies()
    },

    beforeDestroy () {
        clearInterval(this.joinAudioInterval)
        this.joinAudioInterval = null
    },
}
</script>

<style>
#zmmtg-root {
    display: none;
}

#zoom-client-element {
    pointer-events: none;
    z-index: 999999999999;
    background-color: transparent;
}

/* footer butonların soldaki ilk bölümü. kamera ve mikrofon aç-kapat */
html:not(.meeting-host) footer#wc-footer > .footer__inner > div:first-child > * {
    display: none !important;
}

/* ekranın sol üst köşesindeki görüşme hakkında bilgilendirme ve görüşmenin kaydı ile ilgili pause stop vb. butonlar */
.meeting-info-container.meeting-info-container--left-side {
    display: none !important;
}

/* Ekran genişliği daraldığında alttaki butonlar Daha Fazla isimli bir buton altına giriyor. */
html:not(.meeting-host) .footer__inner .more-button {
    display: none !important;
}

/* Ekranın alt kısmındaki "Güvenlik" etiketli buton */
html:not(.meeting-host) .security-option-menu {
    display: none !important;
}

/* Ekranın alt kısmındaki "Katılımcılar" etiketli buton */
[aria-label*="participant"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Anketler" etiketli buton */
html:not(.meeting-host) [aria-label*="polling"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Ekran Paylaş" etiketli buton ve yan ögeleri */
html:not(.meeting-host) .sharing-entry-button-container {
    display: none !important;
}

/* Ekranın alt kısmındaki "Pause/Stop Recording" etiketli butonlar */
html:not(.meeting-host) .footer-button-pause-stop-recording {
    display: none !important;
}

/* Ekranın alt kısmındaki "AI Companion" etiketli buton */
html:not(.meeting-host) [feature-type*="AICompanion"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Reactions" etiketli buton */
html:not(.meeting-host) [feature-type*="reaction"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Settings" etiketli buton */
html:not(.meeting-host) [feature-type*="settings"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Altyazilar" etiketli buton */
html:not(.meeting-host) [feature-type*="newLTT"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Settings" etiketli buton */
html:not(.meeting-host) [aria-label*="Settings"] {
    display: none !important;
}

/* notification manager */
html:not(.meeting-host) #notificationManager {
    display: none !important;
}
</style>
