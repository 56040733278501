import { Watermark } from "../classes/Watermark"

export class WatermarkController {
    /**
     * @type {NodeJS.Timer}
     */
    #interval = null

    /**
     * @type {Watermark[]}
     */
    #watermarks = []

    constructor() {
        this.wrapperElement = document.querySelector('#zoom-client-element')

        // todo burayi css ile neden cozemedik?
        this.wrapperElement.style.position = 'absolute'
        this.wrapperElement.style.width = `${window.clientWidth}px`
        this.wrapperElement.style.height = `${window.clientHeight}px`
        this.wrapperElement.style.top = `${window.offsetTop}px`
        this.wrapperElement.style.left = `${window.offsetLeft}px`
    }

    setWatermarks (blueprints = []) {
        if (!this.#interval) {
            blueprints.forEach(blueprint => {
                this.#addWatermark(blueprint.text, blueprint?.style)
            })

            this.interval = setInterval(() => {
                this.#watermarks.forEach(watermark => watermark.refreshPosition())

                if (this.#watermarks.length === 0) {
                    clearInterval(this.interval)
                    this.interval = null
                }
            }, 10000)
        }
    }

    #addWatermark(text, style = {}) {
        const watermark = new Watermark(text, style)
        this.#watermarks.push(watermark)
        this.wrapperElement.appendChild(watermark.getElement())
    }
}