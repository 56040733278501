
export class JoinedMeetingEvent extends CustomEvent {
    static name = 'joinedMeeting'

    constructor() {
        super(JoinedMeetingEvent.name, {
            bubbles: true,
            cancelable: true,
        })
    }
}