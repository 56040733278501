export class ZoomSignatureController {
    #axios = require('axios')
    #endpoint
    #token

    #sdkKey
    #signature
    #meetingNumber
    #passWord
    #userEmail
    #userName

    constructor(endpoint, token) {
        this.#endpoint = endpoint
        this.#token = token
    }

    getSignature () {
        return this.#axios.get(
            this.#endpoint,
            {
                headers: {
                    Authorization: `Token ${this.#token}`
                }
            })
            .then(res => {
                this.#sdkKey = res.data.sdkKey
                this.#signature = res.data.signature
                this.#meetingNumber = res.data.meetingNumber
                this.#passWord = res.data.passWord
                this.#userEmail = res.data.userEmail
                this.#userName = res.data.userName
            })
            .catch(error => {
                console.log(error)
            })
    }

    getMeetingConfigs() {
        return {
            sdkKey: this.#sdkKey,
            signature: this.#signature,
            meetingNumber: this.#meetingNumber,
            passWord: this.#passWord,
            userEmail: this.#userEmail,
            userName: this.#userName
        }
    }
}