import { JoinedMeetingEvent } from "../events/JoinedMeetingEvent"

export class ZoomMtgController {
    #meeting
    #config = {}
    #leaveUrl
    #isHost

    constructor(leaveUrl) {
        this.#meeting = window.ZoomMtg
        this.#leaveUrl = leaveUrl
    }

    authorizeForHost() {
        this.#isHost = true

        this.#config = {
            // disablePreview: true, // optional
            disablePictureInPicture: true,
            isSupportAV: true,
            // isSupportPolling: true, //optional
            leaveUrl: this.#leaveUrl,
            // videoDrag: false, //optional,
        }
    }

    authorizeForAttendee() {
        this.#isHost = false

        this.#config = {
            // debug: false, //optional
            audioPanelAlwaysOpen: false, //optional
            disableCallOut: true, //optional
            // disableCORP: true, // optional
            disableInvite: true, //optional
            disableJoinAudio: false, //optional
            disablePictureInPicture: true,
            disablePreview: true, // optional
            disableRecord: true, //optional
            disableReport: true, // optional
            disableVoIP: false, // optional
            // inviteUrlFormat: '', // optional
            // isLockBottom: true, // optional,
            // isShowJoiningErrorDialog: true, // optional,
            isSupportAV: true, //optional,
            // isSupportBreakout: true, //optional
            // isSupportCC: false, //optional,
            isSupportNonverbal: false, // optional,
            isSupportPolling: true, //optional
            // isSupportQA: true, //optional,
            // leaveUrl: 'http://www.zoom.us', //required
            leaveUrl: this.#leaveUrl,
            // loginWindow: {  // optional,
            //     width: 400,
            //     height: 380
            // },
            // meetingInfo: [ // optional
            //     'topic',
            //     'host',
            //     'mn',
            //     'pwd',
            //     'telPwd',
            //     'invite',
            //     'participant',
            //     'dc',
            //     'enctype',
            //     'report'
            // ],
            // rwcBackup: '', //optional,
            screenShare: false, //optional,
            // sharingMode: 'both', //optional,
            showMeetingHeader: false, //option
            showPureSharingContent: true, //optional
            videoDrag: false, //optional,
            videoHeader: false, //optional,
            // webEndpoint: 'PSO web domain', // PSO option
        }
    }

    initMeeting(configs = {}) {
        this.#showUI()

        this.#meeting.init({
            ...this.#config,
            success: () => {
                this.joinMeeting(configs)
            },
            error: (error) => {
                console.log(error)
            }
        })

        // this.#meeting.inMeetingServiceListener('onUserUpdate', (data) => {
        // });

        window.ZoomMtg.inMeetingServiceListener('onMeetingStatus', (data) => {
            // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
            // console.log('inMeetingServiceListener onMeetingStatus', data)
            // 2 => meeting baslatildi
            if (data?.meetingStatus === 2) {
                // http requestte başka bir sorun olabileceği gerekçesiyle sorgudan önce event fırlatmak
                window.top.postMessage('meetingConnected', '*')

                window.top.postMessage('meetingConnectedRequest', '*')
            }
        })
    }

    #showUI() {
        // todo buraya neden ihtiyac duyduk?
        // ZoomClient.vue component icerisinde css ile ilk basta display none yapilmis
        document.getElementById('zmmtg-root').style.display = 'block'
    }

    joinMeeting(configs = {}) {
        this.#meeting.join({
            ...configs,
            success: () => {
                if (!this.#isHost) {
                    this.#meeting.showJoinAudioFunction({show: false})
                }
                
                document.dispatchEvent(new JoinedMeetingEvent())
            },
            error: (error) => {
                console.log(error)
            }
        })
    }
}