export class Watermark {
    #text
    #style
    #element

    constructor(text, style = {}) {
        this.#text = text
        this.#style = style

        this.#prepareElement()
    }

    #prepareElement() {
        this.#element = document.createElement('p')
        
        this.#element.innerHTML = this.#text

        this.#element.style.position = 'absolute'
        this.#element.style.top = '20px'
        this.#element.style.left = '20px'
        this.#element.style.textShadow = '0px 0px 8px black'
        this.#element.style.color = 'white'
        this.#element.style.opacity = '0.4'
        this.#element.style.fontSize = '18px'
        this.#element.style.fontWeight = 'bold'
        
        // override default style with custom style
        for (const key in this.#style) {
            this.#element.style[key] = this.#style[key]
        }
    }

    getElement() {
        return this.#element
    }

    refreshPosition() {
        this.#element.style.left = `${(window.innerWidth - this.#element.offsetWidth) * Math.random()}px`
        this.#element.style.top = `${(window.innerHeight - this.#element.offsetHeight) * Math.random()}px`
    }
}