<template>
    <div>
        <zoom-client
            v-if="cAbleToView"
            :course-id="cCourseId"
            :leave-url="cLeaveUrl"
            :live-class-id="cLiveClassId"
            :token="cToken"
            :userId="cUserId"
        />

        <div
            v-else
            class="insufficient-info-error"
        >
            <a :href="cLeaveUrl">
                Lütfen bu pencereyi kapatıp tekrar oturum açmayı deneyin.
            </a>
        </div>
    </div>
</template>

<script>
import ZoomClient from '@/components/ZoomClient.vue'

export default {
    name: 'Home',

    components: {
        ZoomClient
    },

    computed: {
        cAbleToView () {
            return this.cRawIncomingInfo && this.cIsIncomingInfoInTime
        },

        cRawIncomingInfo () {
            return this.$route.params.incomingInfo
        },

        cIncomingInfo () {
            return JSON.parse(atob(this.cRawIncomingInfo))
        },

        cCourseId () {
            return this.cIncomingInfo.course
        },

        cLeaveUrl () {
            return this.cIncomingInfo.host
        },

        cLiveClassId () {
            return this.cIncomingInfo.live
        },

        cIncomingInfoTime () {
            return this.cIncomingInfo.time
        },

        cIsIncomingInfoInTime () {
            const now = new Date().getTime()

            return this.cIncomingInfoTime + (60 * 1000) > now
        },

        cToken () {
            return this.cIncomingInfo.token
        },

        cUserId () {
            return this.cIncomingInfo.userId
        }
    }
}
</script>

<style scoped>
.insufficient-info-error {
    width: 100%;
    padding: 24px;
    font-weight: bold;
}

.insufficient-info-error a {
    color: white;
}
</style>
